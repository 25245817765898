import "lazysizes";
import "lazysizes/plugins/parent-fit/ls.parent-fit";
import "virtual:svg-icons-register";

import "../styles/theme.scss";
// import "./modules/PageNavigation";

import Widget from "@widgets/index";

interface ModuleDefinition {
  selector: string;
  moduleName: string;
}

const moduleDefinition : ModuleDefinition[] = [
  { selector: ".gallery", moduleName: "Gallery" },
  { selector: ".card-swiper", moduleName: "CardSwiper" },
];

const widgetDefinition : ModuleDefinition[] = [
  { selector: ".generic-form", moduleName: "BookingForm" },
  { selector: ".interactive-menu", moduleName: "InteractiveMenu" },
];

const bootstrap = {
  init() {
    (moduleDefinition ?? []).forEach(({ selector, moduleName }) => {
      (document.querySelectorAll<HTMLElement>(selector) ?? []).forEach((el) => this.modules.loader(el, moduleName));
    });

    import("@modules/AnimatedScroll").then((mod) => {
      const Module = mod.default;
      return new Module().init();
    });

    this.widgets.init();
    this.headerVideo.init();
    this.siteNav.init();
    this.cssVars.init();
  },
  widgets: {
    init: () => {
      (widgetDefinition ?? []).forEach(({ selector, moduleName }) => {
        (document.querySelectorAll<HTMLElement>(selector) ?? []).forEach((el) => new Widget(el, moduleName));
      });

      window.addEventListener("initForm", (ev) => {
        if (ev.detail.element) {
          return new Widget(ev.detail.element, ev.detail.widget);
        }

        return false;
      });
    },
  },
  siteNav: {
    init() {
      (document.querySelectorAll<HTMLElement>(".nav-toggle") ?? []).forEach((el) => {
        el.addEventListener("click", this.toggle.bind(this));
      });
    },
    toggle(ev: MouseEvent) {
      const icon = (ev.currentTarget as HTMLButtonElement).querySelector("svg use");
      if (icon) {
        icon.setAttribute(
          "xlink:href",
          document.documentElement.classList.contains("menu-open") ? "#icon-menu" : "#icon-menu-close",
        );
      }
      document.documentElement.classList.toggle("menu-open");
    },
  },
  modules: {
    loader: (el: HTMLElement, moduleName: string) => {
      try {
        return import(`@modules/${moduleName}.ts`).then((mod) => {
          const Module = mod.default;
          return new Module(el);
        });
      } catch (error: unknown) {
        const err = error as Error;
        // eslint-disable-next-line no-console
        console.warn(err);
      }

      return false;
    },
  },
  headerVideo: {
    init: () => {
      // console.log("init");
      const video = document.querySelector<HTMLVideoElement>(".header__video");

      if (video !== null) {
        const sources = video.getElementsByTagName("source");

        if (window.outerWidth < 481) {
          sources[0].src = video.dataset.mobileSrc ?? sources[0].src;
        }

        video.load();
        video.play();
      }
      return true;
    },
  },
  cssVars: {
    init() {
      this.setWidth();
      window.addEventListener("resize", this.setWidth.bind(this), { passive: true });
    },
    setWidth() {
      const scrollDiv = document.createElement("div");
      scrollDiv.className = "scrollbar-measure";
      document.body.appendChild(scrollDiv);
      document.documentElement.style.setProperty("--scrollbar-width", `${scrollDiv.offsetWidth - scrollDiv.clientWidth}px`);
      document.body.removeChild(scrollDiv);
    },
  },
};

document.addEventListener("DOMContentLoaded", () => {
  bootstrap.init();
});
