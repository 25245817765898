import { render } from "preact";

class Widget {
  element: HTMLElement;

  widgetName: string;

  constructor(element: HTMLElement, module: string) {
    this.element = element;
    this.widgetName = module;

    this.init();
  }

  init() {
    import((`@widgets/${this.widgetName}/index.ts`)).then(({ default: WidgetComponent }) => {
      render((<WidgetComponent dataset={this.element.dataset} />), this.element);
    });
  }
}

export default Widget;
